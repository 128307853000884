import * as React from "react"
// import PropTypes from "prop-types"


const Masthead = () => (
  <div className="masthead-bg">
    <div className="container m-auto" >
      <a name="top"></a>
      <div className="w-full pl-4 pt-14 pb-80 lg:w-1/3 md:w-1/2 md:pt-32 lg:pb-60">
        <div className="masthead-headline animate__animated animate__fadeInLeft">
        The Smart Choice for Smart Technology Integration
        </div>
        <div className="masthead-subheadline animate__animated animate__fadeInLeft">
        We guarantee Quality On Implementation. <br/>
        It’s in our name.
        </div>
        <div className="animate__animated animate__fadeInLeft">
          <a href="#aboutus" className="btn-cta">Learn More</a>
        </div>
      </div>
    </div>
  </div>
)

export default Masthead