import * as React from "react"

const SmartShades = () => (
  <div className="smart-shades smartshades-bg" id="smartshades">
    <div className="card">
      <div className="services-label">
      QOI SMART SHADES
      </div>
      <div className="services-headline">
      Smart and sophisticated window solutions
      </div>
      <div>
        <ul className="grid grid-cols-2">
          <li>Automated Shades</li>
          <li>Roman Shades</li>
          <li>Drapery</li>
          <li>Vertical Shades</li>
          <li>High-lite Shades</li>
          <li>Horizontal Shades</li>
          <li>Cellular Shades</li>
        </ul>
        <a href="https://www.qoismartshades.com/" className="rounded-full bg-blue-300 px-6 py-4 my-4 inline-block font-bold uppercase text-white" target="_blank">Learn More</a>
      </div>
    </div>
  </div>
)

export default SmartShades