import * as React from "react"

const Security = () => (
  <div className="security security-bg" id="security">
    <div className="card">
      <div className="services-label">
      QOI HOME SECURITY
      </div>
      <div className="services-headline">
      Smarter yet 
simpler security management
      </div>
      <div>
        <ul>
          <li>Alarm System</li>
          <li>Camera System</li>
          <li>24-Hour Monitoring</li>
        </ul>
        <a href="/home-security" className="rounded-full learn-more px-6 py-4 my-4 inline-block font-bold uppercase text-white">Learn More</a>

      </div>
    </div>
  </div>
)

export default Security