import * as React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import Masthead from "./masthead"
import WhoWeAre from "./whoweare"
import WhatWeDo from "./whatwedo"
import Technology from "./technology"
import Security from "./security"
import SmartShades from "./smartshades"
import Products from "./products"
import ProductsCarousel from "./products-carousel"
import ContactUs from "./contact-us"
import Footer from "./footer"


if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const Home = ({ siteTitle }) => {
  return (
  <div>
    <Nav/>
    <Masthead/>
    <WhoWeAre/>
    <WhatWeDo/>
    <Technology/>
    <Security/>
    <SmartShades/>
    <Products/>
    <ProductsCarousel/>
    <ContactUs/>
    <Footer/>
  </div>
  )
  }

export default Home