import * as React from "react"

const Technology = () => (
  <div className="technology technology-bg" id="technology">
    <div className="card">
      <div className="services-label">
      QOI HOME TECHNOLOGY
      </div>
      <div className="services-headline">
      Making your smart home smarter 
and simpler
      </div>
      <div>
        <ul>
          <li>Whole Home Integration</li>
          <li>Custom Home Theatre</li>
          <li>Networking</li>
          <li>Multi-Room Audio/Video</li>                          
          <li>Smart Lighting</li>
        </ul>
        <a href="/home-technology" className="rounded-full learn-more px-6 py-4 my-4 inline-block font-bold uppercase text-white">Learn More</a>

      </div>
    </div>
  </div>
)

export default Technology
