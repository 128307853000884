import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WhatWeDo = () => (
  <div className="w-full what-we-do text-center p-8 md:p-16" id="whatwedo">
    <div className="heading">
      What We Do
    </div>
    <div className="">
      <div className="w-8 border-t-8 my-6 border-accent inline-block">

      </div>
      <div className="mb-8">
      Systems such as heating, electricity, and security keep your life afloat. Managing these smart systems
are easier by utilizing the latest technology and innovations, for a price of your choice.
      </div>     

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 container mx-auto 2xl:px-48">
        
        <div className="rounded-lg bg-white text-center p-4 shadow-lg">
          <div className="text-black py-2">
            QOI Home Technology
          </div>
          <a href="#technology">
          <div className="rounded-full w-28 h-28 md:w-48 md:h-48 bg-technology inline-block p-6 md:p-11 md:my-4">
            <StaticImage src="../images/icon-technology@2x.png" className="icon align-middle" alt="Home Technology"/>
          </div>
          </a>
        </div>

        <div className="rounded-lg bg-white text-center p-4 shadow-lg">
          <div className="text-black py-2">
            QOI Home Security
          </div>
          <a href="#security">
          <div className="rounded-full w-28 h-28 md:w-48 md:h-48 bg-security inline-block p-6 md:p-11 md:my-4">
            <StaticImage src="../images/icon-security@2x.png" className="icon align-middle" alt="Home Security"/>
          </div>
          </a>
        </div>

        <div className="rounded-lg bg-white text-center p-4 shadow-lg">
          <div className="text-black py-2">
            QOI Smart Shades
          </div>
          <a href="#smartshades">
          <div className="rounded-full w-28 h-28 md:w-48 md:h-48 bg-smartshades inline-block p-6 md:p-11 md:my-4">
            <StaticImage src="../images/icon-smartshades@2x.png" className="icon align-middle" alt="Smart Shades"/>
          </div>
          </a>
        </div>               

      </div>


    </div>
  </div>
)

export default WhatWeDo