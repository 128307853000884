import * as React from "react"

const WhoWeAre = () => (
  <div className="w-full who-we-are text-center p-8 md:p-16 lg:px-80" id="aboutus">
    <div className="heading">
      Who We Are
    </div>
    <div className="">
      <div className="w-8 border-t-8 my-6 border-accent inline-block">

      </div>
      <div className="mb-8">
      QOI Systems Integration is a Vancouver-based company that offers quality smart home systems
integration at an affordable price so you can make the most out of your home or business.
      </div>     
      <div className="grid grid-cols-1 md:grid-cols-2 text-left">
        
        <div className="flex">
          <div className="p-2">
            <div className="rounded-full w-8 h-8 bg-accent"></div>
          </div>
          <div className="p-2">
            <strong>Experienced</strong>
            <p>
            Over a decade of providing smart and reliable system integration
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="p-2">
            <div className="rounded-full w-8 h-8 bg-accent"></div>
          </div>
          <div className="p-2">
            <strong>Innovative</strong>
            <p>
            Convenience thanks to our custom centralized system
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="p-2">
            <div className="rounded-full w-8 h-8 bg-accent"></div>
          </div>
          <div className="p-2">
            <strong>Trusted</strong>
            <p>
            We are licensed, insured and bonded
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="p-2">
            <div className="rounded-full w-8 h-8 bg-accent"></div>
          </div>
          <div className="p-2">
            <strong>Affordable</strong>
            <p>
            You can customize your package to fit any budget
            </p>
          </div>
        </div>                        
      
      </div>
    </div>
  </div>
)

export default WhoWeAre